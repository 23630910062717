import { GET,POST,DELETE } from '../utils/http'
import config from '../../constans';
let url = `${config.baseUrl.apiUrl}`
let cityUrl = `${config.baseUrl.cityUrl}/insurance/api`

//城市列表
export const selectByArea =()=> {
  return POST(`${cityUrl}/area/selectByArea`)
}
//雇员列表
export const employeeList =(params)=> {
  return GET(`${url}/other/qz/employeeList`,params)
}
//更改、新增雇员
export const employeeSave =(params)=> {
  return POST(`${url}/other/qz/employeeSave`,params)
}
//删除雇员
export const employeeRemove =(params)=> {
  return DELETE(`${url}/other/qz/remove/employee`,params)
}
//投保
export const insure =(params)=> {
  return POST(`${url}/other/qz/save`,params)
}

















