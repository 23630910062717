import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'index',
        component: () => import("../view/index"),
        meta: {
            title: '轻住集团酒店保险',
        }
    },
    {
        path: '/information',
        name: 'information',
        component: () => import("../view/information"),
        meta: {
            title: '投保录入',
            isBack: false,
            keepAlive: true,
        }
    },
    {
        path: '/employeeList',
        name: 'employeeList',
        component: () => import("../view/employeeList"),
        meta: {
            title: '雇员列表',
        }
    },
    
    {
        path: '/pdf',
        name: 'pdf',
        component: () => import("../view/pdf"),
        meta: {
            title: '查看',
        }
    },
]
const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, saveTop) {
        if (saveTop) { 
            return saveTop; 
        } else { 
            return { x: 0, y: 0 } 
        } 
    },
    base: process.env.BASE_URL,
    routes
})

export default router